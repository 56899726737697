module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"John Goodliff | Software Developer","description":"Portfolio site for John Goodliff, a software developer based in Edmonton, Alberta.","short_name":"John Goodliff","start_url":"/","background_color":"#131313","theme_color":"#2DD4BF","display":"standalone","icon":"/home/runner/work/jerboa88.github.io/jerboa88.github.io/src/images/icon.svg","screenshots":[{"src":"images/og/index.webp","sizes":"2400x1260","type":"image/webp"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"80958ea6cecabc9e5b4a04a12097cc3e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
